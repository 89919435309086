var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plan",
      style: _vm.style,
      on: {
        mouseenter: _vm.mouseenter,
        mouseleave: _vm.mouseleave,
        mousemove: _vm.onMouseMove,
        mouseup: _vm.mouseup
      }
    },
    [
      _vm.showRuler
        ? _c("ruler", {
            ref: "ruler",
            attrs: {
              axisX: _vm.axisX,
              axisY: _vm.axisY,
              rulerWidth: _vm.rulerWidth
            }
          })
        : _vm._e(),
      _vm.showAxis && _vm.listen
        ? _c("axis", {
            attrs: { rulerWidth: _vm.rulerWidth, x: _vm.mouseX, y: _vm.mouseY }
          })
        : _vm._e(),
      _c("div", { staticClass: "plan-content", style: _vm.labelStyle }, [
        _vm.bgImg
          ? _c("img", {
              attrs: { src: _vm.bgImg, width: "100%", height: "100%", alt: "" }
            })
          : _vm._e(),
        _c(
          "div",
          { ref: "label", staticClass: "label" },
          _vm._l(_vm.boxList, function(info, idx) {
            return _c(
              "box",
              {
                key: idx,
                attrs: {
                  idx: idx,
                  info: info,
                  ratioX: _vm.ratioX,
                  ratioY: _vm.ratioY,
                  drag: _vm.drag || _vm.editIdx === idx,
                  move: _vm.move || _vm.editIdx === idx,
                  hover: _vm.hover || _vm.editIdx === idx,
                  select: _vm.select || _vm.editIdx === idx,
                  isSelect: _vm.selectIdx === idx,
                  isHover: _vm.hoverIdx === idx
                },
                on: {
                  move: _vm.onMove,
                  drag: _vm.onDrag,
                  select: _vm.onSelect,
                  hover: _vm.onHover
                }
              },
              [_vm._t("box", null, { data: info })],
              2
            )
          }),
          1
        ),
        _vm.$slots.tooltips
          ? _c(
              "div",
              { staticClass: "tooltips", style: _vm.tooltipStyle },
              [_vm._t("tooltips")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }